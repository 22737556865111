window.delay = function(callback, ms) {
  var timer = 0;
  return function() {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}

window.dataTablesInitComplete = function() {
  $('.dt-filter input.daterangepicker-input').daterangepicker({
    autoUpdateInput: false,
    format: "YYYY/MM/DD",
    locale: {
      cancelLabel: 'Clear'
    }
  });

  this.api().columns().every(function (index) {
    let table = this;
    let filter_header = $(table.table().header()).find(`.dt-filter td:eq(${index})`)
    $('input, select', filter_header).on('click', function(event) {
      event.stopPropagation();
    })
    $('input, select', filter_header).on('keyup change clear', delay(function () {
      table.columns(index).search(this.value).draw();
    }, 500));
    $('input, select', filter_header).on('data', function () {
      table.columns(index).search(this.value);
    })
    $("span", filter_header).on('DOMSubtreeModified', function () {
      table.columns(index).search($(this).html()).draw();
    });
    $('input.daterangepicker-input', filter_header).on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('YYYY/MM/DD') + ' - ' + picker.endDate.format('YYYY/MM/DD'));
      $(this).trigger('change')
    });
    $('input.daterangepicker-input', filter_header).on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
      $(this).trigger('change')
    });
  });
  $('[data-bs-toggle="tooltip"]').tooltip()
}

window.dataTablesInitColumnFilters = function(table, resource) {
  $(`.dataTables-${resource}-col-filters li.toggle-vis input`).on( 'change', function (e) {
    let column = table.column( $(this).attr('data-column') )
    column.visible( this.checked )
  })
  $('button.toggle-vis-all').on( 'click', function (e) {
    $(`.dataTables-${resource}-col-filters li.toggle-vis input`).each( (i, e) => {
      let column = table.column( $(e).attr('data-column') )
      column.visible(true)
    })
    $(`.dataTables-${resource}-col-filters li.toggle-vis input`).prop('checked', true)
  })
}

window.dataTablesInitClearFilters = function(table, selector = '#clear-filters', callback) {
  $(selector).on('click',(e) =>{
    $('.dt-filter input').val('')
    $('.dt-filter select').each( (i, select) => {
      let option = $(select).find('option:first').val()
      if (option == '' || option == null || /all/i.test(option))
        $(select).val(option)
      else
        $(select).val('')
    })
    table.columns().search('').draw()
    if (callback) {
      callback()
    }
  });
}

window.dataTablesResponsiveFilter = function(e, table, columns) {
  for (i=0; i<columns.length; i++) {
    if (columns[i]) {
      $(e.target).find('.dt-filter td:eq(' + i + ')' ).show();
    } else {
      $(e.target).find('.dt-filter td:eq(' + i + ')' ).hide();
    }
  }
}

window.dataTablesUseResponsive = function() {
  return localStorage.dataTablesUseResponsive == 'true'
}

window.dataTablesChangeResponsive = function() {
  localStorage.dataTablesUseResponsive = localStorage.dataTablesUseResponsive == 'true' ? 'false' : 'true'
  window.location.reload()
}

window.dataTablesInitSelect = function(table_selector, select_all_selector, onSomeSelected) {
  $(table_selector).on('draw.dt', function() {
    $(select_all_selector).prop("checked", false);
    onSomeSelected(false)
  });

  $(table_selector).on('change', '.datatable-checkbox', function() {
    let select_all = $(select_all_selector).get(0);
    if (this.checked) {
      onSomeSelected(true)
      if (select_all && ('indeterminate' in select_all)) {
        select_all.indeterminate = true;
      }
    } else {
      if ($('.datatable-checkbox:checked').length === 0) {
        onSomeSelected(false)
        if (select_all && ('indeterminate' in select_all)) {
          select_all.indeterminate = false;
        }
      } else {
        if (select_all && ('indeterminate' in select_all)) {
          select_all.indeterminate = true;
        }
      }
    }
  });

  $(select_all_selector).on('click', function(){
    $('.datatable-checkbox').prop('checked', this.checked);
    onSomeSelected(this.checked)
  });
}

window.create_modal = function(id, title, size = 'lg') {
  if ($(`#${id}`).length > 0) {
    $(`#${id} .modal-title`).text(title);
    $(`#${id}`).detach().appendTo('#modals')
  } else {
    $('#modals').append(
      `<div class="modal fade" id="${id}" tabindex="-1" role="dialog" aria-labelledby="${id}_label" aria-hidden="true">
        <div class="modal-dialog modal-${size}">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="${id}_label">${title}</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          <div class="modal-body"></div>
      </div></div></div>`
    )
  }
}

window.dataTablesRenderActions = function(actions) {
  return actions.filter((action) => action.url).map( action =>
    `<a class="action-icon" data-method="${action.method == undefined ? 'get' : action.method}" href="${action.url}" data-remote="${action.remote || 'false'}" ${action.new_window ? "target='_blank'" : ''} ${action.confirm ? `data-confirm="${action.confirm}"` : ''}>${action.icon && `<i class="${action.icon}"></i>`}</a>`
  ).join('')
}
